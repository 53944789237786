import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class Faq extends Component {
  render() {
    return (
	<div className="row">
		<div className="col-md-12">
			<h1>Поширенi запитання</h1>
			<h3>1. Як зареєструватися в кампусі або отримати логін та пароль?</h3>
			<p>
				<b>Для викладачів</b> - зверніться до відповідального на кафедрі або зав. кафедрою для подачі службової
				<a href="https://document.kpi.ua/2024_RP-294" target="_blank">за зразком.</a>
			</p>
			<p>
				<b>Для студентів</b> - зверніться до вашого <a href="https://ecampus.kpi.ua/find-curator"
					target="_blank">академ.куратора.</a>
			</p>
			<p>
				<b>Для аспірантів</b> - зверніться до відділу аспірантури та доктарантури
			</p>

			<h3>2. Як відновити пароль?</h3>
			<p>Якщо ви змінювали пароль і додавали електронну пошту, то для відновлення паролю скористайтесь <a href="https://ecampus.kpi.ua/restore-password" terget="_blank">кнопкою</a> на панелі авторизації.</p>
			<p>Якщо ви не змінювали пароль або не певні в цьому, то для відновлення паролю - див. п.1 вище.</p>

			<h2>Від викладачів</h2>

			<h3>3. Що робити, якщо група є (створюється навантаження), а студентів в ній немає?</h3>
			<p>В такому випадку потрібно звернутися до деканату вашого підрозділу, щоб відповідальна особа розподілила студентів по групам.</p>
			<h3>4. Що робити, якщо студент є в групі (в куратора або в Календарному контролі), але він відсутінй в поточному контролі?</h3>
			<p>В такому випадку потрібно додати студента вручну, через відповідне поле під списком групи в Поточному контролі</p>

			<h2>Від студентів</h2>
			<h3>5. Що робити, якщо в мене в кабінеті студента неправильна група?</h3>
			<p>В такому випадку потрібно звернутися до деканату вашого підрозділу, щоб відповідальна особа розподілила студентів по групам.</p>
			<h3>6. Що робити, якщо в мене в ""Поточному контролі" в кабінеті студента зайві (вибіркові) дисципліни?</h3>
			<p>Зв'язатися через старосту або одногрупників із викладачем і попросити видалити вас зі списку.</p>
		</div>
	</div>
    );
  }
}

export default Faq;
